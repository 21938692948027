var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal-slide" } }, [
    _vm.visible
      ? _c(
          "div",
          {
            staticClass: "modal",
            on: {
              touchmove: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "dialog",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    if ($event.target !== $event.currentTarget) {
                      return null
                    }
                  },
                },
              },
              [
                _vm.title
                  ? _c("div", { staticClass: "dialog-title" }, [
                      _c("h3", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.title)),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "dialog-body" },
                  [_vm._t("default")],
                  2
                ),
                _vm._v(" "),
                _vm.$slots.actions
                  ? _c(
                      "div",
                      { staticClass: "dialog-actions" },
                      [_vm._t("actions")],
                      2
                    )
                  : _vm._e(),
              ]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }