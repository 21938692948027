/**
 * @Author: panezhang
 * @Date: 2018-06-01 20:12:27.415
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-12-27 16:12:46
 */

import {createHostRouter} from 'ssr-common/util/net/host-router';

import centuryRongTaiRoutes from 'moneycan/app/century-rongtai/routes';
import easycashIndoRoutes from 'moneycan/app/easycash-indo/routes';
import fintopiaClubRoutes from 'moneycan/app/fintopia-club/routes';
import godDuckRoutes from 'moneycan/app/god-duck/routes';
import guanranTech from 'moneycan/app/guanran-tech/routes';
import klXiaoDaiRoutes from 'moneycan/app/kl-xiaodai/routes';
import lingyueTech from 'moneycan/app/lingyue-tech/routes';
import moneycanLoanRoutes from 'moneycan/app/moneycan-loan/routes';
import snxguaranteeRoutes from 'moneycan/app/snxguarantee/routes';

const hostRouter = createHostRouter();

hostRouter.host('indo.geteasycash.asia *.geteasycash.id *.fintopia.tech', easycashIndoRoutes);
hostRouter.host('fintopia.club *.fintopia.club', fintopiaClubRoutes);
hostRouter.host('moneycan.loan *.moneycan.loan', moneycanLoanRoutes);
hostRouter.host('www.rockyislandcapital.com *.rockyislandcapital.com', godDuckRoutes);
hostRouter.host('sjrtguarantee.com *.sjrtguarantee.com sjrtguarantee.cn *.sjrtguarantee.cn', centuryRongTaiRoutes);
hostRouter.host('snxguarantee.com *.snxguarantee.com snxguarantee.cn *.snxguarantee.cn', snxguaranteeRoutes);
hostRouter.host('klxiaodai.com *.klxiaodai.com klxiaodai.cn *.klxiaodai.cn klloan.com *.klloan.com', klXiaoDaiRoutes);
hostRouter.host('www.lingyuetech.com *.lingyuetech.com', lingyueTech);
hostRouter.host('www.lingyuekeji.tech *.lingyuekeji.tech lingyuekeji.tech', lingyueTech);
hostRouter.host('guanrantech.com *.guanrantech.com', guanranTech);

export default hostRouter;
