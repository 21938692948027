var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: _vm.withoutTransition ? "" : "yqg-modal" } },
    [
      _vm.open
        ? _c(
            "div",
            _vm._g(
              {
                directives: [
                  { name: "prevent-scroll", rawName: "v-prevent-scroll" },
                ],
                staticClass: "yqg-modal",
                class: _vm.yqgModalClass,
              },
              _vm.$listeners
            ),
            [
              _c(
                "div",
                { staticClass: "modal-content" },
                [_vm._t("default")],
                2
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }