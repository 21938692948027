/**
 * @author qingliu
 * @email qingliu@yangqianguan.com
 * @date 2018-07-06 17:04:01.380
 * @desc generated by yqg-cli@0.0.14
 */

export default {
    name: 'Modal',

    props: {
        visible: {
            type: Boolean,
            default: false
        },

        title: String
    },

    methods: {
        handleClose() {
            const vm = this;
            vm.$emit('close');
        }
    },

    mounted() {
        document.body.style.overflow = 'hidden';
    },

    beforeDestroy() {
        document.body.style.overflow = 'auto';
    }
};
