/**
 * @Author: panezhang
 * @Date: 2018-06-01 20:12:27.415
 * @Last Modified by: hongweihu
 * @Last Modified time: 2023-10-23 13:00:31
 */

import Vuex, {Store} from 'vuex';

import Vue from 'ssr-common/vue';

import companyInfo from './company-info/company-info';
import menuOpen from './menu-open/menu-open';

Vue.use(Vuex);

export const createStore = () => new Store({
    state: {
        menuOpen: menuOpen.state()
    },

    modules: {
        companyInfo,
        menuOpen
    }
});
