/**
 * @Author: center
 * @Date: 2021/5/19-4:52 下午
 * @Last Modified by: hongweihu
 * @Last Modified time: 2023-10-23 13:22:34
 */

import * as G_TYPES from './g-type';
import * as M_TYPES from './m-type';

export {
    M_TYPES,
    G_TYPES
};

export default {
    state: () => ({
        companyInfo: {
            companyName: '',
            copyright: '',
            address: '',
            mobile: ''
        }
    }),

    getters: {
        [G_TYPES.GET_COMPANY_INFO]: state => state.companyInfo,
    },

    mutations: {
        [M_TYPES.SET_COMPANY_INFO]: (state, companyInfo) => {
            state.companyInfo = companyInfo;
        }
    }
};
