/**
 * @author panezhang
 * @email panezhang@yangqianguan.com
 * @date 2018-06-01 20:12:27.415
 * @desc generated by yqg-cli@0.1.12
 */

import component from 'moneycan/common/component';
import plugin from 'moneycan/common/plugin';

import {genWebDeviceToken} from 'ssr-common/util/browser-util';
import Vue from 'ssr-common/vue';
import directive from 'ssr-common/vue/directive';
import http from 'ssr-common/vue/vue-http';

// Set Http Headers
const {common} = http.defaults.headers;
common.build = '20000';
common['YQG-PLATFORM-SDK-TYPE'] = 'IDN_YQD';
common['YQG-PLATFORM-DEVICE-TOKEN'] = genWebDeviceToken();
common['YQG-PLATFORM-LANGUAGE'] = 'id';
common.PlatformType = 'ANDROID';

// // Set for Vue
Vue.use(component);
Vue.use(plugin);
Vue.use(directive);

export default {
    name: 'Moneycan'
};
