/**
 * @author panezhang
 * @email panezhang@yangqianguan.com
 * @date 2018-06-01 20:12:27.415
 * @desc generated by yqg-cli@0.1.12
 */

import Vue, {VueRouter, createVueRouter} from 'ssr-common/vue';

import {DEV_HOST} from '../constant/config';
import hostRouter from './routes';

Vue.use(VueRouter);

export const createRouter = ({host}) => {
    const routes = hostRouter.get(DEV_HOST || host);
    if (!routes || !routes.length) throw new Error('404'); // TODO handle 404?

    return createVueRouter({routes});
};
