/**
 * @author KylesLight
 * @date 5/3/17-7:17 PM
 * @file modal
 */

import Vue from 'vue';

import {createVueInstanceEle} from '../helper/helper';

class Modal {

    open(component, resolved = {}) { // eslint-disable-line class-methods-use-this
        if (!component) {
            throw new Error('Please pass component');
        }

        const ModalEleId = `modal${Date.now()}`;
        const modalInstance = new Vue({
            ...component
        });

        createVueInstanceEle(ModalEleId);

        Object.assign(modalInstance, resolved);
        modalInstance.$mount(`#${ModalEleId}`);
        modalInstance.visible = true;

        const destroy = () => {
            if (!document.body.contains(modalInstance.$el)) return;
            modalInstance.visible = false;
            modalInstance.$destroy();
            document.body.removeChild(modalInstance.$el);
        };

        return new Promise((resolve, reject) => {
            Object.assign(modalInstance, {
                resolve(data) {
                    destroy();
                    resolve(data);
                },

                reject() {
                    destroy();
                    reject();
                }
            });
        });
    }

}

export default Modal;
