/*
 * @Author: hongweihu
 * @Date: 2019-12-04 16:33:46
 * @Last Modified by: hongweihu
 * @Last Modified time: 2021-12-15 13:48:15
 */

export default [
    {
        path: '*',
        name: 'main',
        component: () => import('moneycan/app/kl-xiaodai/index')
    }
];
