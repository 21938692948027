/**
 * @Author: panezhang
 * @Date: 2018/6/2-12:39
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-03-29 下午03:48:08
 */

const matchSingeRuleExp = (ruleExp, host) => {
    const domain = host
        .replace(/^https?:\/\//, '')
        .replace(/\?.*^/, '');

    if (!/\*/.test(ruleExp)) {
        return ruleExp === domain;
    }

    const regex = new RegExp(
        ruleExp
            .replace(/\*/g, '\\w+')
            .replace(/\./g, '\\.')
    );

    return regex.test(domain);
};

const matchRuleExp = (ruleExp = '', host) =>
    ruleExp.split(/\s/)
        .some(singleRuleExp => matchSingeRuleExp(singleRuleExp, host));

class HostRouter {

    rules = [];

    host(ruleExp, payload) {
        if (ruleExp && payload) {
            this.rules.push({ruleExp, payload});
        }
    }

    get(host) {
        const matchedRule = this.rules.find(rule => matchRuleExp(rule.ruleExp, host));

        return matchedRule && matchedRule.payload;
    }

}

export const createHostRouter = () => new HostRouter();
