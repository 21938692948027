var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "moneycan-toast-fade" } }, [
    _vm.visible
      ? _c("div", { staticClass: "moneycan-toast", class: _vm.type }, [
          _vm._v(_vm._s(_vm.message)),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }