/**
 * @author es
 * @date 2017/12/20-下午2:12
 * @file ToastType
 */

export default {
    info: 'info',
    error: 'error',
    success: 'success'
};
