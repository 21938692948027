/**
 * @author center
 * @date 2021/5/19-4:52 下午
 * @file menu-open
 */

import * as G_TYPES from './g-type';
import * as M_TYPES from './m-type';

export {
    M_TYPES,
    G_TYPES
};

export default {
    state: () => ({
        menuOpen: false
    }),

    getters: {
        [G_TYPES.GET_MENU_OPEN]: state => state.menuOpen
    },

    mutations: {
        [M_TYPES.SET_MENU_OPEN]: (state, menuOpen) => (state.menuOpen = menuOpen)
    }
};
