var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { BrowserTracing, init, vueRouterInstrumentation } from '@sentry/vue';
import { STAGE } from '../constant/config';
export function initSentry(_a) {
    var app = _a.app, router = _a.router, throwTestError = _a.throwTestError, rest = __rest(_a, ["app", "router", "throwTestError"]);
    try {
        init(__assign({ app: app, environment: STAGE, dsn: 'https://e5d618acb10c4d7292f1ea634822c8ba@sentry.fintopia.tech/4', integrations: [
                new BrowserTracing({
                    routingInstrumentation: vueRouterInstrumentation(router)
                })
            ], debug: true, sampleRate: 1.0, tracesSampleRate: 1.0 }, rest));
    }
    catch (err) {
    }
    if (throwTestError) {
        setTimeout(function () {
            throw new Error('sentry test');
        }, 3e3);
    }
}
