/*
 * @Author: hongweihu
 * @Date: 2019-12-04 16:33:46
 * @Last Modified by: hongweihu
 * @Last Modified time: 2022-06-09 18:35:01
 */

export default [
    {
        path: '*',
        name: 'main',
        component: () => import('moneycan/app/lingyue-tech/index')
    },

    {
        path: '/payment-information-list',
        name: 'paymentInformationList',
        component: () => import('moneycan/app/lingyue-tech/payment-information/list')
    },

    {
        path: '/payment-information-detail/:index',
        name: 'paymentInformationDetail',
        component: () => import('moneycan/app/lingyue-tech/payment-information/detail')
    }
];
