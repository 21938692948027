/**
 * @author panezhang
 * @email panezhang@yangqianguan.com
 * @date 2018-06-01 20:12:27.415
 * @desc generated by yqg-cli@0.1.12
 */

import AntdIcon from './antd-icon';
import Modal from './Modal';
import Toast from './Toast';
import {checkNotNull} from './Valid/valid';

export default {

    install(Vue) {
        const $modal = new Modal();
        const $toast = new Toast();

        Object.assign(Vue.prototype, {
            $modal,
            $toast,
            $valid: {
                checkOptions(data = {}, options = []) {
                    if (!options.length) return true;

                    return options.every(option => {
                        const {
                            field = '',
                            required = false
                        } = option;

                        const fieldData = data[field];

                        // check required
                        if (required && !checkNotNull(fieldData)) {
                            $toast.error('Harap mengisi seluruh data formulir!');

                            return false;
                        }

                        return true;
                    });
                }
            }
        });

        Vue.use(AntdIcon);
    }
};
