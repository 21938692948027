/**
 * @author panezhang
 * @date 2018/7/11-01:43
 * @file routes
 */

// node_modules

// system modules

export default [
    {
        path: '/index',
        name: 'index',
        component: () => import('moneycan/app/moneycan-loan/index')
    },
    {
        path: '/security',
        name: 'security',
        component: () => import('moneycan/app/moneycan-loan/security')
    },
    {
        path: '*',
        redirect: '/index'
    }
];
