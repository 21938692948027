/*
 * @Author: liuzhenkun
 * @Date: 2019-01-28 17:37:42
 * @Last Modified by: liuzhenkun
 * @Last Modified time: 2019-01-29 15:23:47
 */

export default [
    {
        path: '/',
        name: 'index',
        component: () => import('moneycan/app/god-duck/index')
    },
    {
        path: '/agreement',
        name: 'agreement',
        component: () => import('moneycan/app/god-duck/agreement')
    },
    {
        path: '*',
        redirect: '/'
    }
];
