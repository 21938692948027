/**
 * @author hongweihu
 * @email hongweihu@yangqianguan.com
 * @date 2021-11-19 19:28:59.957
 * @desc generated by yqg-cli@5.1.2
 */

export default {
    name: 'YqgImageModal',

    props: {
        borderStyle: {
            type: Object,
            default: () => ({})
        },

        maskText: {
            type: String,
            default: ''
        },

        open: {
            type: Boolean,
            default: false
        },

        type: {
            type: String
        },

        urlList: {
            type: Array,
            default: () => ([])
        }
    },

    computed: {
        imgStyle() {
            const {borderImg, borderInnerWidth} = this.borderStyle;

            return borderImg
                ? {
                    padding: `${borderInnerWidth}px`,
                    backgroundColor: '#dfdfdf',
                    boxShadow: 'inset 0 4px 8px 0 rgba(0,0,0,0.31)'
                }
                : {};
        }
    }
};
