/**
 * @author panezhang
 * @email panezhang@yangqianguan.com
 * @date 2018-06-01 20:12:27.415
 * @desc generated by yqg-cli@0.1.12
 */
/* global __EASEMOB__, __DEV_HOST__, __OCS__ */

export const EASEMOB = __EASEMOB__;
export const DEV_HOST = __DEV_HOST__;

export const OCS = __OCS__;
