var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.urlList && _vm.urlList.length
    ? _c(
        "yqg-modal",
        _vm._g(
          {
            staticClass: "yqg-img-modal",
            attrs: { open: _vm.open, type: _vm.type },
          },
          _vm.$listeners
        ),
        [
          _c(
            "div",
            { staticClass: "img-container" },
            [
              _vm._l(_vm.urlList, function (url, index) {
                return _c("img", {
                  key: index,
                  style: _vm.imgStyle,
                  attrs: { src: url },
                })
              }),
              _vm._v(" "),
              _vm.maskText
                ? _c("div", { staticClass: "text-mask" }, [
                    _vm._v(
                      "\n            " + _vm._s(_vm.maskText) + "\n        "
                    ),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }