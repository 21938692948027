/**
 * @author KylesLight
 * @date 5/3/17-3:55 PM
 * @file helper
 */

export const createVueInstanceEle = (id) => { // eslint-disable-line
    const doc = document;
    const body = doc.body;
    const ele = doc.createElement('div');

    ele.id = id;
    body.appendChild(ele);
};
