/**
 * @author hongweihu
 * @email hongweihu@yangqianguan.com
 * @date 2021-11-19 19:25:47.393
 * @desc generated by yqg-cli@5.1.2
 */
import ModalTypeMap from 'moneycan/common/constant/modal-type-map';

export default {
    name: 'YqgModal',

    props: {
        needMaskTransition: {
            type: Boolean,
            default: true
        },

        open: {
            type: Boolean,
            default: false
        },

        type: {
            type: String,
            default: ModalTypeMap.longImg
        },

        withoutTransition: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        yqgModalClass() {
            return {
                'yqg-modal-center': this.type === ModalTypeMap.center,
                'mask-transition': this.needMaskTransition
            };
        }
    }
};
