/*
 * @Author: hongweihu
 * @Date: 2019-12-04 16:33:46
 * @Last Modified by: liuzhenkun
 * @Last Modified time: 2020-01-03 11:12:22
 */

export default [
    {
        path: '*',
        name: 'main',
        component: () => import('moneycan/app/century-rongtai/index')
    }
];
