/*
 * @Author: liuzhenkun
 * @Date: 2019-04-15 12:56:11
 * @Last Modified by: xiaodongyu
 * @Last Modified time: 2020-02-26 23:16:21
 */

import AntdIcon from '@ant-design/icons-vue';
import SoundFill from '@ant-design/icons/lib/fill/SoundFill';

AntdIcon.add(SoundFill);

export default AntdIcon;
