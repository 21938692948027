/**
 * @Author: panezhang
 * @Date: 2018-06-01 20:12:27.415
 * @Last Modified by: hongweihu
 * @Last Modified time: 2023-10-23 13:23:52
 */

import Vue from 'ssr-common/vue';

import App from './app';
import {createRouter} from './common/router';
import {createStore} from './common/store';
import {M_TYPES} from './common/store/company-info';

export const createApp = context => {
    const router = createRouter(context);
    const store = createStore(context);
    const {host} = context;
    const isLinYueKJ = /lingyuekeji/.test(host);

    if (isLinYueKJ) {
        store.commit(M_TYPES.SET_COMPANY_INFO, {
            copyright: '京ICP备2023027980号-1',
            companyName: '北京瓴岳科技有限公司',
            shortName: '北京瓴岳科技有限公司',
            address: '北京市朝阳区东三环中路39号院17号楼',
            mobile: '+8613810522063'
        });
    } else {
        store.commit(M_TYPES.SET_COMPANY_INFO, {
            copyright: '京ICP备15051891号-2',
            companyName: '北京瓴岳信息技术有限公司',
            shortName: '北京瓴岳',
            address: '东三环北路19号中青大厦',
            mobile: '+86-10-65009188'
        });
    }

    const app = new Vue({
        router,
        store,
        render: h => h(App) // eslint-disable-line id-length
    });

    return {app, router, store};
};
