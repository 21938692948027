/**
 * @author panezhang
 * @date 2018/7/11-01:43
 * @file routes
 */

export default [
    {
        path: '/index',
        name: 'index',
        component: () => import('moneycan/app/guanran-tech/index')
    },
    {
        path: '*',
        redirect: '/index'
    }
];
