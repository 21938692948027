/**
 * @author es
 * @date 2018/7/9-下午6:48
 * @file toast
 */

import Vue from 'vue';

import {createVueInstanceEle} from '../helper/helper';
import MoneycanToast from './component/toast';
import ToastType from './constant/ToastType';

const ToastEleId = 'toast';

class Toast {

    createInstance() {
        const vm = this;
        createVueInstanceEle(ToastEleId);
        vm.toastInstance = new Vue(MoneycanToast);

        vm.toastInstance.$mount(`#${ToastEleId}`);
    }

    toast(type, message = '', duration = 2000) {
        const vm = this;
        if (!vm.toastInstance) vm.createInstance();

        vm.toastInstance.visible = true;
        vm.toastInstance.message = message;
        vm.toastInstance.type = type;

        if (vm.toastTimer) clearTimeout(vm.toastTimer);
        vm.toastTimer = setTimeout(() => {
            vm.toastInstance.visible = false;
        }, duration);
    }

    info(...arg) {
        const vm = this;
        vm.toast(ToastType.info, ...arg);
    }

    success(...arg) {
        const vm = this;
        vm.toast(ToastType.success, ...arg);
    }

    error(...arg) {
        const vm = this;
        vm.toast(ToastType.error, ...arg);
    }

}

export default Toast;
