/*
 * @Author: hongweihu
 * @Date: 2019-12-04 16:33:46
 * @Last Modified by: hongweihu
 * @Last Modified time: 2021-09-07 15:16:57
 */

export default [
    {
        path: '/index',
        name: 'index',
        component: () => import('moneycan/app/snxguarantee/index')
    },
    {
        path: '*',
        redirect: '/index'
    }
];
