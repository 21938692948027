/**
 * @author panezhang
 * @email panezhang@yangqianguan.com
 * @date 2018-06-01 20:12:27.415
 * @desc generated by yqg-cli@0.1.12
 */

import MoneycanModal from './modal';
import YqgImageModal from './yqg-image-modal';
import YqgModal from './yqg-modal';

export default {

    install(Vue) {
        Vue.component('MoneycanModal', MoneycanModal);
        Vue.component(YqgImageModal.name, YqgImageModal);
        Vue.component(YqgModal.name, YqgModal);
    }
};
