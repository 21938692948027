/**
 * @author qingliu
 * @email qingliu@yangqianguan.com
 * @date 2018-07-09 18:52:17.245
 * @desc generated by yqg-cli@0.2.4
 */

import ToastType from '../../constant/ToastType';

export default {
    data() {
        return {
            visible: false,
            message: '',
            type: ToastType.INFO
        };
    }
};

