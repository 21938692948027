/**
 * @author panezhang
 * @date 2018/7/11-01:42
 * @file routes
 */

export default [
    {
        path: '/',
        name: 'index',
        component: () => import('moneycan/app/easycash-indo/index')
    },
    {
        path: '/webview',
        component: () => import('moneycan/app/easycash-indo/webview'),
        children: [
            {
                path: '/webview/help-center',
                component: () => import('moneycan/app/easycash-indo/webview/help-center'),
                children: [
                    {
                        path: '/webview/help-center/loan',
                        component: () => import('moneycan/app/easycash-indo/webview/help-center/loan')
                    },
                    {
                        path: '/webview/help-center/repayment',
                        component: () => import('moneycan/app/easycash-indo/webview/help-center/repayment')
                    }
                ]
            },
            {
                path: '/webview/agreement',
                component: () => import('moneycan/app/easycash-indo/webview/agreement')
            },
            {
                path: '/webview/optional-info',
                component: () => import('moneycan/app/easycash-indo/webview/optional-info')
            },
            {
                path: '/webview/profile',
                component: () => import('moneycan/app/easycash-indo/webview/profile')
            },
            {
                name: 'Lender',
                path: '/webview/lender',
                component: () => import('moneycan/app/easycash-indo/webview/lender'),
                children: [
                    {
                        name: 'LenderSignIn',
                        path: '/webview/lender/sign-in',
                        component: () => import('moneycan/app/easycash-indo/webview/lender/sign-in')
                    },
                    {
                        name: 'LenderSignUp',
                        path: '/webview/lender/sign-up',
                        component: () => import('moneycan/app/easycash-indo/webview/lender/sign-up')
                    },
                    {
                        name: 'LenderBorrowerList',
                        path: '/webview/lender/borrower-list',
                        component: () => import('moneycan/app/easycash-indo/webview/lender/borrowser-list')
                    },
                    {
                        name: 'LenderAgreement',
                        path: '/webview/lender/agreement',
                        component: () => import('moneycan/app/easycash-indo/webview/lender/agreement')
                    }
                ]
            }
        ]
    },
    {
        path: '*',
        redirect: '/'
    }
];
